import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import "./sidebarcontent.scss";
import SimpleBar from "simplebar-react";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  EyeIcon,
  SettingsIcon,
  UserProfileIcon,
  UpgradeToProLogo,
  UpgradeToProBtnIcon,
  AnalysisIcon,
  TeamInboxIcon,
  IntegrationsIcon,
} from "./SideBarIcons";
import { checkForPermission } from "../../util/commonfunctions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { userCreditHistory, orgCreditHistory, getPlanList } from "../../redux/auth/auth.action";
import { selectUserCreditHistory } from "../../redux/auth/auth.selector";
import { useTranslation } from "react-i18next";
import AiIcon from "../../assets/icons/eazybe-three-green-star.png";

const SidebarContent = (props) => {
  const ref = useRef();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem("currentOrgId")) {
      props.getOrgCreditHistory(localStorage.getItem("currentOrgId"));
    } else {
      props.getUserCreditHistory(localStorage.getItem("workspaceId"));
    }
    props.fetchPlanList();

    // Collapse the sidebar by default on mount
    props.setSideNavExpanded(false);
  }, []);

  // Expand on hover
  const handleMouseEnter = () => {
    props.setSideNavExpanded(true);
  };

  // Collapse when mouse leaves
  const handleMouseLeave = () => {
    props.setSideNavExpanded(false);
  };

  // Collapse sidebar on link click
  const handleLinkClick = () => {
    props.setSideNavExpanded(false);
  };

  return (
    <React.Fragment>
      <SimpleBar
        className="h-90"        
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div id="sidebar-menu-new">
          <ul className="sidebar-items d-flex-col" id="side-menu-new">
            {/* Dashboard */}
            <li className="sidebar-item">
              <NavLink
                activeClassName="active-main-nav"
                to="/conversations/analytics"
                className="nav-org"
                isActive={() =>
                  ["/dashboard", "/conversations/analytics"].includes(location.pathname)
                }
                onClick={handleLinkClick}
              >
                <div className="sidebar-icon-container">
                  <AnalysisIcon className="sidebar-icon calls-icon" />
                </div>
                <span>{t("Dashboard.message")}</span>
              </NavLink>
            </li>

            {/* Team Inbox */}
            {checkForPermission(1) && (
              <li className="sidebar-item">
                <NavLink
                  activeClassName="active-main-nav"
                  to="/team-inbox/"
                  className="nav-org"
                  isActive={() => ["/team-inbox/"].includes(location.pathname)}
                  onClick={handleLinkClick}
                >
                  <div className="sidebar-icon-container">
                    <TeamInboxIcon className="sidebar-icon users-icon" />
                  </div>
                  <span>{t("Team_inbox.message")}</span>
                </NavLink>
              </li>
            )}

            {/* Organization */}
            {checkForPermission(1) && (
              <li className="sidebar-item">
                <NavLink
                  activeClassName="active-main-nav"
                  to="/organization"
                  className="nav-org"
                  onClick={handleLinkClick}
                >
                  <div className="sidebar-icon-container">
                    <EyeIcon className="sidebar-icon" />
                  </div>
                  <span>{t("Organisation.message")}</span>
                </NavLink>
              </li>
            )}

            {/* Integrations */}
            <li className="sidebar-item">
              <NavLink
                activeClassName="active-main-nav"
                to="/integrations"
                className="nav-org"
                onClick={handleLinkClick}
              >
                <div className="sidebar-icon-container">
                  <IntegrationsIcon className="sidebar-icon" />
                </div>
                <span>{t("Integrations.message")}</span>
              </NavLink>
            </li>

            {
              localStorage.getItem("currentOrgId") && localStorage.getItem("currentOrgId") === "902" ? 
             ( <li className="sidebar-item">
              <NavLink
                activeClassName="active-main-nav"
                to="/knowledge-base"
                className="nav-org"
              >
                <div className="sidebar-icon-container">
                  <img src={AiIcon} className="sidebar-icon m-0" alt="eazybe-ai" />
                </div>
                {/* <span>{t("Integrations.message")}</span> */}
                <span>Eazybe AI</span>
              </NavLink>
            </li>) : null
            }

            {/* Settings */}
            <li className="sidebar-item">
              <NavLink
                activeClassName="active-main-nav"
                to="/settings"
                className="nav-settings"
                onClick={handleLinkClick}
              >
                <div className="sidebar-icon-container">
                  <UserProfileIcon className="sidebar-icon" />
                </div>
                <span>{t("My_Account.message")}</span>
              </NavLink>
            </li>

            {/* Upgrade Section */}
            <li className="upgradeToPro">
              <div className="upgradeToProLogo">
                <UpgradeToProLogo />
              </div>
              <div className="upgradeToProContent">
                <Link to={"/settings/billing"}>
                  <div className="upgradeToProFooter">
                    <h6 style={{ marginRight: "5px" }}>{t("Upgrade_now.message")}</h6>
                    <UpgradeToProBtnIcon />
                  </div>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  setSideNavExpanded: PropTypes.func.isRequired,
  getOrgCreditHistory: PropTypes.func.isRequired,
  getUserCreditHistory: PropTypes.func.isRequired,
  fetchPlanList: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  userCreditHistory: selectUserCreditHistory,
});

const mapDispatchToProps = (dispatch) => ({
  getOrgCreditHistory: (org_id) => dispatch(orgCreditHistory(org_id)),
  getUserCreditHistory: (workspace_id) => dispatch(userCreditHistory(workspace_id)),
  fetchPlanList: () => dispatch(getPlanList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarContent));
