import { API as APIGRAPH, graphqlOperation } from "aws-amplify";
import axios from "axios";
import { differenceInDays, getHours, subDays } from "date-fns";
import {
  API,
  APP_URL_PREFIX,
  AWS_CALLS_URL,
  AWS_CHATS_URL,
} from "../../util/api";
import DashboardActionTypes from "./dashboard.types";
import { getQuery, QUERY_DASHBOARD_TYPES } from "./queries";

export const setOrganizationId = (id) => {
  return (dispatch) => {
    dispatch({
      type: DashboardActionTypes.SET_ORGANISATION_ID,
      payload: id,
    });
  };
};

// <<<<<<<<AWS_CALL_LOGS>>>>>>>>

export const getSplineChartsData = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(getNewCallsOverViewAws());
      dispatch(getActiveCallHours());

      let teamsMobileList =
        getState().layout.activeEmployee === "All"
          ? await getTeamsUserIdList(getState().organization.teamsList)
          : getState().layout.activeEmployee;

      if (teamsMobileList && teamsMobileList.length) {
        // query
        let query1 = await getQuery(
          getState().layout.filterDate.startDate,
          getState().layout.filterDate.endDate,
          teamsMobileList,
          QUERY_DASHBOARD_TYPES.TOTAL_CALLS_COUNT
        );

        let query2 = await getQuery(
          getState().layout.filterDate.startDate,
          getState().layout.filterDate.endDate,
          teamsMobileList,
          QUERY_DASHBOARD_TYPES.TOTAL_CALLS_DURATION
        );

        let query3 = await getQuery(
          getState().layout.filterDate.startDate,
          getState().layout.filterDate.endDate,
          teamsMobileList,
          QUERY_DASHBOARD_TYPES.UNIQUE_CALLS_COUNT
        );

        let query4 = await getQuery(
          getState().layout.filterDate.startDate,
          getState().layout.filterDate.endDate,
          teamsMobileList,
          QUERY_DASHBOARD_TYPES.NEW_CALLS
        );

        // request
        let response1 = await axios.post(
          `${
            APP_URL_PREFIX + API.CALL_THROUGH_AWS
          }?url=${AWS_CALLS_URL}/calllogs/_search`,
          query1
        );

        let response2 = await axios.post(
          `${
            APP_URL_PREFIX + API.CALL_THROUGH_AWS
          }?url=${AWS_CALLS_URL}/calllogs/_search`,
          query2
        );

        let response3 = await axios.post(
          `${
            APP_URL_PREFIX + API.CALL_THROUGH_AWS
          }?url=${AWS_CALLS_URL}/calllogs/_search`,
          query3
        );

        let response4 = await axios.post(
          `${
            APP_URL_PREFIX + API.CALL_THROUGH_AWS
          }?url=${AWS_CALLS_URL}/chatter/_search`,
          query4
        );

        // response
        let totalResponse = await Promise.all([
          response1,
          response2,
          response3,
          response4,
        ]);

        if (totalResponse.length) {
          console.log("Total Response", totalResponse);

          dispatch({
            type: DashboardActionTypes.UPDATE_TOTAL_CALLS_COUNT_SPLINE,
            payload: totalResponse[0].data.aggregations[2].buckets,
          });

          dispatch({
            type: DashboardActionTypes.UPDATE_TOTAL_CALLS_DURATION_SPLINE,
            payload: totalResponse[1].data.aggregations[2].buckets,
          });

          dispatch({
            type: DashboardActionTypes.UPDATE_UNIQUE_CALLS_COUNT_SPLINE,
            payload: totalResponse[2].data.aggregations[2].buckets,
          });

          dispatch({
            type: DashboardActionTypes.UPDATE_NEW_CALLS_COUNT_SPLINE,
            payload: totalResponse[3].data.aggregations[2].buckets,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getCallsOverViewAws = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(getCallsOverViewAwsForDateComparision())

      let teamsMobileList =
        getState().layout.activeEmployee === "All"
          ? await getTeamsUserIdList(getState().organization.teamsList)
          : getState().layout.activeEmployee;

      if (teamsMobileList && teamsMobileList.length) {
        let query = await getQuery(
          getState().layout.filterDate.startDate,
          getState().layout.filterDate.endDate,
          teamsMobileList,
          QUERY_DASHBOARD_TYPES.CALL_OVERVIEW
        );

        let response = await axios.post(
          `${
            APP_URL_PREFIX + API.CALL_THROUGH_AWS
          }?url=${AWS_CALLS_URL}/calllogs/_search`,
          query
        );

        if (response?.data?.aggregations) {
          let buckets = response.data.aggregations[2].buckets;
          let map = new Map();

          console.log("calls over", buckets);

          buckets.forEach((bucket) => {
            let bucketItem = bucket["5"].buckets;
            bucketItem.forEach((item) => {
              if (!map.has(item.key)) {
                map.set(item.key, {
                  [bucket.key]: {
                    totalCount: item.doc_count,
                    totalDuration: item["4"].value,
                    uniqueCalls: item["6"].value,
                    userId: item.key,
                  },
                  team: getTeamDataForUserId(
                    item.key,
                    getState().organization.teamsList
                  ),
                  user: getUserDataForUserId(
                    item.key,
                    getState().organization.teamsList
                  ),
                  userId: item.key,
                });
              } else {
                let mapItem = map.get(item.key);
                mapItem[bucket.key] = {
                  totalCount: item.doc_count,
                  totalDuration: item["4"].value,
                  uniqueCalls: item["6"].value,
                  userId: item.key,
                };
              }
            });
          });

          dispatch({
            type: DashboardActionTypes.UPDATE_CALLS_OVERVIEW,
            payload: map,
          });

          setTimeout(() => {
            dispatch(updateDashboardLoader());
          }, 500);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getCallsOverViewAwsForDateComparision = () => {
  return async (dispatch, getState) => {
    try {
      let teamsMobileList =
        getState().layout.activeEmployee === "All"
          ? await getTeamsUserIdList(getState().organization.teamsList)
          : getState().layout.activeEmployee;

      if (teamsMobileList && teamsMobileList.length) {
        let dateDifference = differenceInDays(new Date(getState().layout.filterDate.endDate),new Date(getState().layout.filterDate.startDate) )

        let query1 = await getQuery(
          subDays(new Date(getState().layout.filterDate.startDate),dateDifference).toJSON().split("T")[0],
          getState().layout.filterDate.startDate,
          teamsMobileList,
          QUERY_DASHBOARD_TYPES.TOTAL_COMPARISION_IN_DAYS
        );


        let query2 = await getQuery(
          getState().layout.filterDate.startDate,
          getState().layout.filterDate.endDate,
          teamsMobileList,
          QUERY_DASHBOARD_TYPES.TOTAL_COMPARISION_IN_DAYS
        );


        let response1 = axios.post(
          `${
            APP_URL_PREFIX + API.CALL_THROUGH_AWS
          }?url=${AWS_CALLS_URL}/calllogs/_search`,
          query1
        );

        let response2 = axios.post(
          `${
            APP_URL_PREFIX + API.CALL_THROUGH_AWS
          }?url=${AWS_CALLS_URL}/calllogs/_search`,
          query2
        );

        let response = await Promise.all([response1,response2])

        console.log("combined", response)

        // if (response.data) {
        //   let buckets = response.data.aggregations[2].buckets;
        //   let map = new Map();

        //   if(buckets?.length) {
        //      buckets.forEach((bucket) => {
        //     let bucketChildArray = bucket["3"].buckets;
        //     if(bucketChildArray?.length) {
        //       bucketChildArray.forEach((item) => {
        //         if (!map.has(bucket.key)) {
        //           map.set(bucket.key, item.doc_count);
        //         } else {
        //           let mapItem = map.get(bucket.key);
        //           map.set(bucket.key, item.doc_count - mapItem);
        //         }
        //       });
        //     }
        //   });
        //   }

        //   dispatch({
        //     type: DashboardActionTypes.CALL_OVERVIEW_COMPARITION_BY_DATE,
        //     payload: map,
        //   });

        // }
      }
    } catch (error) {
      console.error("Failed to get calls overview data by dates comparision",error);
    }
  };
};

export const getNewCallsOverViewAws = () => {
  return async (dispatch, getState) => {
    try {
      let teamsMobileList =
        getState().layout.activeEmployee === "All"
          ? await getTeamsUserIdList(getState().organization.teamsList)
          : getState().layout.activeEmployee;

      if (teamsMobileList && teamsMobileList.length) {
        let query = await getQuery(
          getState().layout.filterDate.startDate,
          getState().layout.filterDate.endDate,
          teamsMobileList,
          QUERY_DASHBOARD_TYPES.NEW_CALL_OVERVIEW
        );

        let response = await axios.post(
          `${
            APP_URL_PREFIX + API.CALL_THROUGH_AWS
          }?url=${AWS_CALLS_URL}/chatter/_search`,
          query
        );

        if (response.data) {
          let buckets = response.data.aggregations[2].buckets;
          let map = new Map();

          buckets.forEach((item) => {
            let bucketItem = item["3"].buckets;
            let incoming = bucketItem.find((it) => it.key === "1");
            let outgoing = bucketItem.find((it) => it.key === "2");

            if (!map.has(item.key)) {
              map.set(item.key, {
                totalCount: item.doc_count,
                incoming: incoming ? incoming.doc_count : 0,
                outgoing: outgoing ? outgoing.doc_count : 0,
                team: getTeamDataForUserId(
                  item.key,
                  getState().organization.teamsList
                ),
                user: getUserDataForUserId(
                  item.key,
                  getState().organization.teamsList
                ),
                userId: item.key,
              });
            }
          });

          dispatch({
            type: DashboardActionTypes.UPDATE_NEW_CALLS_OVERVIEW,
            payload: map,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};
export const getActiveCallHours = () => {
  return async (dispatch, getState) => {
    try {
      let teamsMobileList =
        getState().layout.activeEmployee === "All"
          ? await getTeamsUserIdList(getState().organization.teamsList)
          : getState().layout.activeEmployee;

      if (teamsMobileList && teamsMobileList.length) {
        let query = await getQuery(
          getState().layout.filterDate.startDate,
          getState().layout.filterDate.endDate,
          teamsMobileList,
          QUERY_DASHBOARD_TYPES.ACTIVE_HOURS
        );

        let response = await axios.post(
          `${
            APP_URL_PREFIX + API.CALL_THROUGH_AWS
          }?url=${AWS_CALLS_URL}/calllogs/_search`,
          query
        );

        if (response.data) {
          dispatch({
            type: DashboardActionTypes.UPDATE_ACTIVE_HOURS,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getActiveCallHoursForChats = () => {
  return async (dispatch, getState) => {
    try {
      let teamsMobileList =
        getState().layout.activeEmployee === "All"
          ? await getTeamsMobileList(getState().organization.teamsList)
          : getState().layout.activeEmployee;

      if (teamsMobileList && teamsMobileList.length) {
        let query = await getQuery(
          getState().layout.filterDate.startDate,
          getState().layout.filterDate.endDate,
          teamsMobileList,
          QUERY_DASHBOARD_TYPES.ACTIVE_HOURS_CHATS
        );

        let response = await axios.post(
          `${
            APP_URL_PREFIX + API.CALL_THROUGH_AWS
          }?url=${AWS_CHATS_URL}/whatsappmessages/_search`,
          query
        );

        if (response.data) {
          let buckets = response.data.aggregations[2].buckets;
          let map = new Map();

          buckets.forEach((item) => {
            let time = getHours(item.key);
            if (time >= 8 && time <= 20) {
              if (!map.has(time)) {
                map.set(time, item.doc_count);
              } else {
                let mapItem = map.get(time);
                map.set(time, mapItem + item.doc_count);
              }
            }
          });

          if (map.size) {
            map = new Map([...map].sort((a, b) => a[0] - b[0]));
          }

          dispatch({
            type: DashboardActionTypes.UPDATE_ACTIVE_HOURS_CHAT,
            payload: map,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};


const addChatterToCallLogs = (chatterList, callsList) => {
return callsList.map(item => ({
  ...item,
  chatter: chatterList.find(it => it.Number === item.ChatterNumber)
}))

}

const getCallsChatterListForCallLogs = (listOfNumber) => {
return new Promise(async (resolve,reject) => {
  try{ 

  // if(listOfNumber?.length) {

  //   // remove all the duplicate data
  //   let distinctListOfNumber = removeDuplicats(listOfNumber)
  //   let chatterData = await APIGRAPH.graphql(
  //     graphqlOperation(listChatters, {
  //       filter: {
  //         or: await getNumberArrayForChatter(distinctListOfNumber),
  //       },
  //       limit: 100
  //     }))
  
  //     resolve(chatterData.data.listChatters.items)
  // }

  resolve([])
  } catch(e) {
    console.error("Failed to chatters for call logs",e)
    resolve([]);
  }
  
})
}

export const getFilteredCallLogsAws = (nextTkn) => {
  return async (dispatch, getState) => {
    try {
      // let teamsMobileList =
      //   getState().layout.activeEmployee === "All"
      //     ? await getTeamsUserIdList(getState().organization.teamsList)
      //     : getState().layout.activeEmployee;

      // if (teamsMobileList && teamsMobileList.length) {
      //   let callsData = await APIGRAPH.graphql(
      //     graphqlOperation(listCallLogs, {
      //       filter: {
      //         or: await getContactsArray(teamsMobileList),
      //         Datetime: {
      //           gte: new Date(getState().layout.filterDate.startDate).toJSON(),
      //           lte: new Date(getState().layout.filterDate.endDate).toJSON(),
      //         }
      //       },
      //       limit: 13,
      //       ...(nextTkn ? { nextToken: nextTkn } : {}),
      //     })
      //   );
      //   let {items,nextToken} = callsData.data.listCallLogs;

      //   // for chatters of the call logs
      //   let clientMobileList = items.map(item => item.ChatterNumber)
      //   let chatterList = []
      //   if(clientMobileList.length) {
      //       // get chatter list for client mobile list items.
      //       chatterList = await  getCallsChatterListForCallLogs(clientMobileList)
      //   }

      //   dispatch({
      //     type: DashboardActionTypes.UPDATE_CALL_LOGS,
      //     callLogsList: addChatterToCallLogs(chatterList, items),
      //     token: nextToken,
      //     ...(nextTkn ? { reset: false } : { reset: true }),
      //   });
      // }
    } catch (error) {
      console.error("Failed to fetch call logs", error);
    }
  };
};

export const getFilteredCallLogsForEmployeeAws = (userId, nextToken) => {
  return async (dispatch) => {
    try {
      // if (userId) {
      //   let callsData = await APIGRAPH.graphql(
      //     graphqlOperation(listCallLogs, {
      //       filter: {
      //         CreatedByUser: {
      //           eq: userId,
      //         },
      //       },
      //       ...(nextToken ? { nextToken: nextToken } : {}),
      //     })
      //   );
      //   let response = callsData.data.listCallLogs;

      //   dispatch({
      //     type: DashboardActionTypes.UPDATE_EMPLOYEE_CALL_LOGS,
      //     callLogsList: response.items,
      //     token: response.nextToken,
      //   });
      // }
    } catch (error) {
      console.error("Failed to fetch call logs", error);
    }
  };
};

const getTeamDataForUserId = (userId, teamList) => {
  if (teamList && teamList.length) {
    let teamData = teamList.find((it) => it.user_id === parseInt(userId));
    return teamData?.callyzer_orgamnization_team
      ? teamData.callyzer_orgamnization_team
      : null;
  } else {
    return null;
  }
};

const getUserDataForUserId = (userId, teamList) => {
  if (teamList && teamList.length) {
    let teamData = teamList.find((it) => it.user_id === parseInt(userId));
    return teamData?.whatzapp_basic_info ? teamData.whatzapp_basic_info : null;
  } else {
    return null;
  }
};

export const updateDashboardLoader = () => {
  return {
    type: DashboardActionTypes.UPDATE_DASHBOARD_LOADER,
    payload: false,
  };
};

const getTeamsUserIdList = (list) => {
  return new Promise((resolve, reject) => {
    if (list?.length) {
      let teamsMobileList = [];

      list.forEach((team) => {
        if (team && team.user_id) {
          teamsMobileList.push(team.user_id.toString());
        }
      });
      console.log("workspaceIds", teamsMobileList);
      resolve(teamsMobileList);
    } else {
      reject();
    }
  });
};
const getTeamsMobileList = (list) => {
  return new Promise((resolve, reject) => {
    let teamsMobileList = [];

    list.forEach((team) => {
      if (team.whatzapp_basic_info && team.whatzapp_basic_info.mobile) {
        teamsMobileList.push(team.whatzapp_basic_info.mobile?.toString());
      }
    });
    resolve(teamsMobileList);
  });
};

const removeDuplicats = (list) => {
  return list.filter((item,index) => {
    return list.indexOf(item) === index;
  })
}

const getNumberArrayForChatter = (array) => {
  return new Promise((resolve, reject) => {
    let contactMatchArray = [];
    array.forEach((contact) => {
      contactMatchArray.push({
        Number: {
          eq: contact,
        },
      });
    });
    resolve(contactMatchArray);
  });
};

const getContactsArray = (array) => {
  return new Promise((resolve, reject) => {
    let contactMatchArray = [];
    array.forEach((contact) => {
      contactMatchArray.push({
        CreatedByUser: {
          eq: contact,
        },
      });
    });
    resolve(contactMatchArray);
  });
};

export const resetEmployeeCallLogs = () => (dispatch) =>
  dispatch({ type: DashboardActionTypes.GET_EMPLOYEE_CALL_LOGS_RESET });
