
const AI_PREFIX_URL = "https://ai.eazybe.com/ai-handler"
const generateReply = async (searchText) => {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({ searchText }),
        };

        const response = await fetch(AI_PREFIX_URL, requestOptions)
        const result = await response.json();
        if(!result || !result.status) {
            throw new Error("Failed to generate ai reply.");
        }

        return result.data;
    } catch (error) {
        console.error("Error in generateReply: ", error);
        return null;
    }
}

export { generateReply };