
import "./knowledge-bot-styles.scss";

import React, { useRef, useState } from "react"
import {
  Shield,
  Eye,
  RotateCcw,
  Link2,
  Share2,
  Maximize2,
  ChevronDown,
  Target,
  Key,
  Grid,
  MessageSquare,
  Send,
} from "react-feather"
import { generateReply } from "../../redux/ai/ai.action";

const Button = ({ children, icon: Icon, ...props }) => (
  <button className="button" {...props}>
    {Icon && <Icon size={16} />}
    {children}
  </button>
)

const IconButton = ({ icon: Icon, ...props }) => (
  <button className="icon-button" {...props}>
    <Icon size={16} />
  </button>
)

const MessageBox = ({ message, type, date }) => {
  if (type === "user") {
    return (
      <div className="message user-message">
        <div style={{ textAlign: "right" }}>
          <div className="message-content">{message}</div>
          <div style={{ fontSize: "0.875rem", color: "#666", marginTop: "4px" }}>{date}</div>
        </div>
        <div style={{ fontSize: "1.5rem" }}>😊</div>
      </div>
    )
  }
  return (
    <div className="message">
      <img
        src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202025-01-22%20at%204.13.26%E2%80%AFPM-dmur10ip0TZ1VmUqqN84pdSCk0wZuF.png"
        alt="AI Avatar"
        className="avatar"
      />
      <div>
        <div className="message-content">{message}</div>
        <div style={{ fontSize: "0.875rem", color: "#666", marginTop: "4px" }}>{date}</div>
      </div>
    </div>
  )
}


const ChatInterface = () => {
  const [input, setInput] = useState("");
  const fileUploadRef = useRef();
  const chatAreaRef = useRef();
  
  const [conversation, setConversation] = useState([
    {
      id: 1,
      message: "Hello! How can I help you today?",
      type: "bot",
      date: new Date().toJSON().replace("T", " ").split(".")[0]
    }
  ]);


  const handleInputChange = (e) => {
    setInput(e.target.value);
    console.log(e.target.value)
    if (!e.target.value) {
      return;
    }
  }
  

  const pushConversation = (message, type) => {
    setConversation(prev => [...prev,
    {
      id: prev.length + 1,
      message,
      type,
      date: new Date().toJSON().replace("T", " ").split(".")[0]
    }]);

    chatAreaRef.current.scrollBy(0, chatAreaRef.current.scrollHeight)

  }

  const handleSendButton = async () => {
    if (!input) {
      return;
    }

    pushConversation(input, "user");
    setInput("");
    const result = await generateReply(input);
    pushConversation(result, "bot");
  }


  const handleKeyDown = (e) => {
    if (e.key !== "Enter") {
      return;
    }
      handleSendButton();
  }

  const handleFileClick = () => fileUploadRef.current.click();
  const handleFileUpload = () => {
    try {
      const fileInput = fileUploadRef.current;
      if (!fileInput) return;

      const selectedFile = fileInput.files[0];

      if (selectedFile.type !== "application/pdf" || selectedFile.size > 15 * 1024 * 1024) {
        alert("Only PDF files up to 15MB are supported.");
        return;
      }

      const reader = new FileReader();

      reader.onload = function (e) {
        const fileContent = e.target.result;
        const blob = new Blob([fileContent], { type: selectedFile.type });
        const url = URL.createObjectURL(blob);
        

        // const link = document.createElement("a");
        // link.href = url;
        // link.download = selectedFile.name;
        // link.click();
        // URL.revokeObjectURL(url);
      };

    reader.readAsText(selectedFile);
    

    } catch (error) {
      console.error("Failed to handle file upload:", error);
    }
  }

  return (
    <div className="chat-container">
      {/* Sidebar */}
      <div className="sidebar">
        <div>
          <h5>NextChat</h5>
          <p>Build your own AI assistant.</p>
        </div>

        <div style={{ display: "flex", gap: "8px", marginBottom: "16px" }}>
          <Button icon={Shield}>Mask</Button>
          <Button icon={Eye}>Discovery</Button>
        </div>

        <div className="card">
          <div>New Conversation</div>
          <div style={{ fontSize: "0.875rem", color: "#666" }}>
            <span>{conversation.length} {conversation.length === 1 ? "message" : "messages"}</span>
            <span style={{ margin: "0 8px" }}>·</span>
            <span>{conversation[conversation.length - 1].date}</span>
          </div>
        </div>

        <div style={{ marginTop: "auto", display: "flex", gap: "8px" }}>
          <IconButton icon={RotateCcw} />
          <IconButton icon={MessageSquare} />
          <Button style={{ flex: 1 }}>New Chat</Button>
        </div>
      </div>

      {/* Main Content */}
      <div className="main-content">
        {/* Header */}
        <div className="header">
          <div>
            <h5>New Conversation</h5>
            <p style={{ fontSize: "0.875rem", color: "#666" }}>{conversation.length} {conversation.length === 1 ? "message" : "messages"}</p>
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            <IconButton icon={RotateCcw} />
            <IconButton icon={Link2} />
            <IconButton icon={Share2} />
            <IconButton icon={Maximize2} />
          </div>
        </div>

        {/* Chat Area */}
        <div className="chat-area" ref={chatAreaRef}>

          {
            conversation.map(conv => <MessageBox {...conv} />)
          }
        </div>

        {/* Input Area */}
        <div className="input-area">
          <div style={{ display: "flex", gap: "8px", marginBottom: "16px" }}>
            <IconButton icon={ChevronDown} onClick={handleFileClick} />
              <input type="file"  hidden ref={fileUploadRef} onChange={handleFileUpload}/>
            <IconButton icon={Target} />
            <IconButton icon={Key} />
            <IconButton icon={Shield} />
            <IconButton icon={MessageSquare} />
            <IconButton icon={Grid} />
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            <input
              className="input"
              placeholder="Enter to send, Shift + Enter to wrap, / to search prompts, : to use commands"
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <Button icon={Send} onClick={handleSendButton} >Send</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatInterface

